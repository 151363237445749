/*
 * ⚠️ ☢️ for the ENV to be accessible inside react app, it must use prefix VITE_ ☢️ ⚠️
 */

export const COMMIT_ID = import.meta.env.VITE_COMMIT_ID;

export const DEFAULT_LANGUAGE = import.meta.env.VITE_DEFAULT_LANGUAGE;

// ADB2C settings
export const ADB2C_INSTANCE = import.meta.env.VITE_ADB2C_INSTANCE;
export const ADB2C_TENANT = import.meta.env.VITE_ADB2C_TENANT;
export const ADB2C_SCOPE_DOMAIN = import.meta.env.VITE_ADB2C_SCOPE_DOMAIN;
export const ADB2C_APPLICATION_ID = import.meta.env.VITE_ADB2C_APPLICATION_ID;
export const ADB2C_POLICY = import.meta.env.VITE_ADB2C_POLICY;
export const ADB2C_INVITATION_POLICY = import.meta.env
  .VITE_ADB2C_INVITATION_POLICY;
export const ADB2C_SIGNUP_POLICY = import.meta.env.VITE_ADB2C_SIGNUP_POLICY;
export const ADB2C_MFA_RENEW_POLICY = import.meta.env
  .VITE_ADB2C_MFA_RENEW_POLICY;
export const ADB2C_REDIRECT_URI = import.meta.env.VITE_ADB2C_REDIRECT_URI;
export const ADB2C_SCOPES = [
  `https://${ADB2C_SCOPE_DOMAIN}/${ADB2C_APPLICATION_ID}/general`,
];

// API connection
export const API_BASE_URI = import.meta.env.VITE_API_BASE_URI;
export const API_ENDPOINT_PREFIX = import.meta.env.VITE_API_ENDPOINT_PREFIX;
export const API_SUBSCRIPTION_KEY = import.meta.env.VITE_API_SUBSCRIPTION_KEY;

/*
 * Feature toggles
 *
 * The default state of each feature toggle is the missing/empty state,
 * only when the toggle has a value "yes", should it come to effect
 *
 * With regards to that definition, choose appropriate names that point to the feature,
 * e.g. "SHOW_LANGUAGE_OPTIONS", "ENABLE_TESTS_PAGE", so it's clear what is the default behavior.
 */
export const ENABLE_DEV_LANG = import.meta.env.VITE_ENABLE_DEV_LANG;
export const ENABLE_I18NEXT_DEBUG = import.meta.env.VITE_ENABLE_I18NEXT_DEBUG;
export const DISABLE_AUTO_LOGOUT = import.meta.env.VITE_DISABLE_AUTO_LOGOUT;

/*
 * Cookies and Analytics
 */
export const COOKIEPRO_SCRIPT_SRC = import.meta.env.VITE_COOKIEPRO_SCRIPT_SRC;
export const COOKIEPRO_SCRIPT_ID = import.meta.env.VITE_COOKIEPRO_SCRIPT_ID;

/*
 * Product Fruits - Introduction Tours
 */
export const PRODUCT_FRUITS_WORKSPACE_CODE = import.meta.env
  .VITE_PRODUCT_FRUITS_WORKSPACE_CODE;

/**
 * App store urls
 */
export const MOBILE_KEY_APP_APP_STORE = import.meta.env
  .VITE_MOBILE_KEY_APP_APP_STORE_URL;
export const MOBILE_KEY_APP_GOOGLE_PLAY = import.meta.env
  .VITE_MOBILE_KEY_APP_GOOGLE_PLAY_URL;

/*
 * Appliation Insights
 */
export const APP_INSIGHTS_CONNECTION_STRING = import.meta.env
  .VITE_APP_INSIGHTS_CONNECTION_STRING;

export const ENABLE_COMPANY_ONBOARDING = import.meta.env
  .VITE_ENABLE_COMPANY_ONBOARDING;

export const ENABLE_MOBILE_KEY_APP = import.meta.env.VITE_ENABLE_MOBILE_KEY_APP;

export const DEV_BAR_COLOR = import.meta.env.VITE_DEV_BAR_COLOR;

export const BANKID_URL = import.meta.env.VITE_BANKID_URL;
export const BANKID_CLIENT_ID = import.meta.env.VITE_BANKID_CLIENT_ID;
